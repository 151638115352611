






























































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";
import Confirm from "@/components/Confirm.vue";

interface Item {
  id: string;
  amount: number;
  dateCreated: Date;
  items: {
    id: string;
    price: number;
    title: string;
  }[];
  name: string;
  status: null | string;
  statusMessage: string;
  uid: string;
  corpName: string;
  billType: "bireysel" | "kurumsal";
  paymentType: "creditcard" | "transfer";
}

interface DownloadItem {
  "Sipariş No": string;
  "Fatura Adı": string;
  "Fatura Türü": string;
  "Ödeme Türü": string;
  "Tutar (₺)": string;
  Durum: string;
  Tarih: string;
  "e-Posta": string;
  Telefon?: string;
  "TC Kimlik No"?: string;
  "Vergi No"?: string;
}

export default Vue.extend({
  components: {
    Confirm
  },

  metaInfo() {
    return {
      title: "Siparişler"
    };
  },

  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Sipariş No",
          align: "start",
          filterable: true,
          value: "id"
        },
        { text: "Fatura Adı", value: "name" },
        { text: "Fatura Türü", value: "billType" },
        { text: "Ödeme Türü", value: "paymentType" },
        { text: "Tutar (₺)", value: "amount" },
        { text: "Durum", value: "status", width: "90px" },
        { text: "Tarih", value: "dateCreated", width: "200px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],

      course: {
        id: "",
        name: "",
        urlCode: ""
      },

      items: [
        {
          id: "",
          uid: "",
          name: "",
          corpName: "",
          items: [
            {
              id: "",
              price: 0,
              title: ""
            }
          ],
          amount: 0,
          status: null,
          statusMessage: "",
          dateCreated: new Date(),
          billType: "bireysel",
          paymentType: "creditcard"
        }
      ] as Item[],

      downloadData: [] as DownloadItem[]
    };
  },

  computed: {
    courseId() {
      return this.$route.params.id;
    },

    breadcrumbs(): any[] {
      return [
        {
          text: "Eğitimler",
          exact: true,
          to: { name: "admin-courses" }
        },
        {
          text: this.course.name,
          exact: true,
          to: { name: "admin-course-edit", params: { id: this.course.id } }
        },
        {
          text: "Siparişler"
        }
      ];
    }
  },

  methods: {
    capitalize(text: string) {
      const words = text.split(" ");

      return words
        .map(word => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
    },

    async getItems() {
      this.loading = true;

      try {
        // Get all courses
        const query = await firebase
          .firestore()
          .collection("/apps/kahev-akademi/orders")
          .orderBy("dateCreated", "desc")
          .get();

        this.items = [];
        query.forEach(doc => {
          if (doc.exists) {
            const items = doc.data().items;
            // Find course in items
            // If it couldn't find, doesn't add it to list
            if (!items.find(i => i.id == this.courseId)) {
              return;
            }

            const order = {
              id: doc.id,
              uid: doc.data().uid,
              name: doc.data().name,
              corpName: doc.data().corpName,
              items: items,
              amount: doc.data().amount,
              status: doc.data().status,
              statusMessage: doc.data().statusMessage,
              dateCreated: doc.data().dateCreated.toDate(),
              billType: doc.data().billType,
              paymentType: doc.data().paymentType
            } as Item;

            this.items.push(order);

            // İndirme verisini oluştur
            const downloadItem = {
              "Sipariş No": order.id,
              "Fatura Adı":
                order.billType == "bireysel" ? order.name : order.corpName,
              "Fatura Türü": this.capitalize(order.billType),
              "Ödeme Türü":
                order.paymentType == "creditcard"
                  ? "Kredi Kartı"
                  : "Para Transferi",
              "Tutar (₺)": order.amount.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              Durum: order.statusMessage,
              Tarih: order.dateCreated.toLocaleString("tr-TR"),
              "e-Posta": doc.data().email,
              Telefon: doc.data().phone ? doc.data().phone : "",
              "TC Kimlik No": doc.data().tckn,
              "Vergi No": doc.data().taxNumber
            } as DownloadItem;

            this.downloadData.push(downloadItem);
          }
        });
      } catch (err) {
        this.$notify({
          type: "error",
          text: `Sipariş listesi alınamadı.`
        });
        throw new Error(`Sipari listesi alınamadı. ${err.message}`);
      } finally {
        this.loading = false;
      }
    },

    async getCourse() {
      const courseRef = await firebase
        .firestore()
        .doc(`/apps/kahev-akademi/courses/${this.courseId}`)
        .get();

      if (courseRef.exists) {
        this.course = {
          id: courseRef.id,
          name: courseRef.data()?.name,
          urlCode: courseRef.data()?.urlCode
        };
      }
    }
  },

  async mounted() {
    await this.getCourse();
    await this.getItems();
  }
});
